/**
* Team Section Component
* Created by Emmanuella Albuquerque on 2023/03/13.
*/

import { Avatar } from '../Avatar';
import marcus from '../../images/team/marcus.png'; 
import eduardo from '../../images/team/eduardo.png'; 
import uilames from '../../images/team/uilames.png';
import luciano from '../../images/team/luciano.png';
import luis from '../../images/team/luis.png';
import renan from '../../images/team/renan.png';
import moleculesteam from '../../images/molecules2.png'; 
import './styles.css';

export function TeamSection() {
  return(
    <section id="team" className="team">
      <h1>Team</h1>
      <div className="image_wrapper_top">
        <img src={moleculesteam} alt="molecules" />
      </div>
      <div className="members-container">
        <div className="coordinator_container">
          <h2>Coordinator</h2>
          <Avatar image={marcus} name="Marcus Tullius Scotti"  />
        </div>

        <div className='divider'></div>

        <div className="devs_container">
          <h2>Devs</h2>
          <div className="avatar_group">
          <Avatar image={eduardo} name="Eduardo Henrique"  />
          <Avatar image={uilames} name="Uilames de Assis"  />
          <Avatar image={luciano} name="Luciano Pereira"  />
          <Avatar image={luis} name="Luis Felipe Melo"  />
          <Avatar image={renan} name="Renan Paiva"  />
          </div>
        </div>
      </div>

      <div className="image_wrapper_bottom">
        <img src={moleculesteam} alt="molecules" />
      </div>
    </section>
  );
}
