/**
* How to cite Template
* Created by Emmanuella Albuquerque on 2023/03/16.
*/

export function Howtocite() {
  return (
    <p>
    {/*Article name goes*/}
    <br/>
    <br/>

      <a href=""
        title="DOI Reference" target="_blank" rel="noopener noreferrer"
      >
        {/*Doi goes here and in href*/}</a>
    </p>

  );
}
