/**
* About Template
* Created by Emmanuella Albuquerque on 2023/03/16.
*/

export function About() {
  return (
    <p>    
      AmIActive uses predictive models trained on data of the ChEMBL database and makes predictions of molecules the researcher 
      wishes to investigate. 
      <br/>
      <br/>

      It informs the qualitative prediction of the molecules, as well as a its probability. 
      AmIActive also provides the possibility for the researcher to download predictions in the .csv format
    </p>
  );
}
